import { ConvertModelToQueryString } from "@utils/Helpers";


export const renderPlateRangeUrl = (apiUrlBase: string, rangeId: number) => {
    return `${apiUrlBase}/plateranges/${rangeId}/image`;
}

export const renderOrderItemPricingUrl = (apiUrlBase: string, transactionType: string, plateDesignId: number, plateSize: number, vehicleTypeId: number, combination: string) => {
    return `${apiUrlBase}/pricing/calculate?transactionType=${transactionType}&plateDesignId=${plateDesignId}&plateSize=${plateSize}&vehicleTypeId=${vehicleTypeId}&combination=${combination}`;
}

export const renderPlateSizeUrl = (apiUrlBase: string, vehicleTypeId: number, combination: string, plateDesignId: number) => {
    return `${apiUrlBase}/platesizes?vehicleTypeId=${vehicleTypeId}&combination=${combination}&plateDesignId=${plateDesignId}`;
}

export const renderPlateColorUrl = (apiUrlBase: string, plateDesignId: number) => {
    return `${apiUrlBase}/platecolours?plateDesignId=${plateDesignId}`;
}
export const renderPlateDesignsUrl = (apiUrlBase: string, vehicleTypeId: number, combination: string, plateRangeId: number) => {
    return `${apiUrlBase}/platedesigns?vehicleTypeId=${vehicleTypeId}&combination=${combination}&plateRangeId=${plateRangeId}`;
}


export const renderPlateImage = (apiUrlBase: string, designId: number, comboLayout: string, plateSizeId: number, fgColorId: number, maxWidth: number) => {
    const result = `${apiUrlBase}/image/${designId}/${comboLayout}/${plateSizeId}/${fgColorId}/max/${maxWidth}`;
    return result;
}

export const renderPlateRangesUrl = (apiUrlBase: string, vehicleTypeId: number, combination: string) => {
    return `${apiUrlBase}/plateranges/?vehicleTypeId=${vehicleTypeId}&combination=${combination}`;
}

export const getplateSuggestions = (apiUrlBase: string, startIndex: number, endIndex: number, leetSpeakAlt: boolean, popularNos: boolean, rangeOfNos: boolean, permutations: boolean, combination: string) => {
    return `${apiUrlBase}/suggest?startIndex=${startIndex}&endIndex=${endIndex}&leetSpeakAlt=${leetSpeakAlt}&popularNos=${popularNos}&rangeOfNumbers=${rangeOfNos}&permutations=${permutations}&viewAll=false&combination=${combination}`
}

export const addSalesforceCampaignMember = (apiUrlBase: string) => {
    return `${apiUrlBase}/salesforce/campaign/add-member`
}
export const getSalesforceCampaignMember = (apiUrlBase: string, campaignName: string, email: string) => {
    return `${apiUrlBase}/salesforce/campaign/get-member?campaignName=${campaignName}&email=${email}`
}
export const syncSalesForceLead = (apiUrlBase: string) => {
    return `${apiUrlBase}/salesforce/lead/sync`
}
export const SalesForceSyncOpportunity = (apiUrlBase: string, basketId: string) => {
    return `${apiUrlBase}/salesforce/sale/${basketId}`
}
export const SalesForceSyncOpportunityPlates = (apiUrlBase: string, basketId: string) => {
    return `${apiUrlBase}/salesforce/sale/plates/${basketId}`
}
export const getSurveyQuestionsUrl = (apiUrlBase: string, transactionId: number, surveyId: number) => {
    return `${apiUrlBase}/nps/${transactionId}/${surveyId}`
}

export const sendEmail = (apiUrlBase: string) => {
    return `${apiUrlBase}/email/send`
}

export const checkCombinationUrl = (apiUrlBase: string, combination: string, vehicleType: number) => {
    return `${apiUrlBase}/combination/${combination}/?vehicleTypeId=${vehicleType}`
}

export const getCaptionFontMapUrl = (apiUrlBase: string) => {
    return `${apiUrlBase}/platefont/`
}

export const salesRecoverySetAttributeUrl = (apiUrlBase: string, basketId: string) => {
    return `${apiUrlBase}/basket/set/${basketId}`
}

export const getTransactionOrderData = (apiUrlBase: string, id: string) => {
    return `${apiUrlBase}/transaction/order-details?key=${id}`;
}

export const getPaymentRequestDataUrl = (apiBaseUrl: string, token: string, fromsalesforce: boolean) => {
    return `${apiBaseUrl}/payrequest/check/${token}?fromsalesforce=${fromsalesforce}`;
}

export const confirmPaymentRequestAmountUrl = (apiBaseUrl: string, token: string, amount: number, paymentMethod: number) => {
    return `${apiBaseUrl}/payrequest/checkamount/${token}?amount=${amount}&method=${paymentMethod}`;
}

export const startPaymentRequestUrl = (apiBaseUrl: string, token: string, amount: number, paymentMethod: number) => {
    return `${apiBaseUrl}/payrequest/start/${token}?amount=${amount}&method=${paymentMethod}`;
}
export const startAfterpayPaymentRequestUrl = (apiBaseUrl: string, token: string, amount: number) => {
    return `${apiBaseUrl}/payrequest/afterpay/${token}?amount=${amount}`;
}
export const getPlatePriceUrl = (apiUrlBase: string, data: any) => {
    const qs = ConvertModelToQueryString(data);
    const url = `${apiUrlBase}/pricing/calculate?${qs}`
    return url;
}
export const getPaymentFromAfterpayRequestUrlByTransactionId = (apiUrlBase: string, transactionId?: number, amount?: number) => {
    return `${apiUrlBase}/payrequest/afterpay/${transactionId}?amount=${amount}`
}
export const renderGetPlateStyleUrl = (apiUrlBase: string, frontPlateDesignId: number, frontForegroundColorId: number, backPlateDesignId: number, backForegroundColorId: number) => {
    return `${apiUrlBase}/basket/platestylecode?frontPlateDesignId=${frontPlateDesignId}&frontForegroundColorId=${frontForegroundColorId}&backPlateDesignId=${backPlateDesignId}&backForegroundColorId=${backForegroundColorId}`
}
export const getValidateCaptchaUrl = (apiUrlBase: string, token: string, version: string) => {
    return `${apiUrlBase}/recaptcha/verify?token=${token}&version=${version}`
}
export const createPaymentRequestForSalesRecoveryUrl = (apiUrl: string, key: string) => {
    return `${apiUrl}/payrequest/sales/recovery?key=${key}`;
}